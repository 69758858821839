<template>
    <div class="membershipCard">
        <div class="info">
            <div class="tr-li">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no">
                        <input type="text" keyBoard placeholder="请输入卡号或读卡" v-model="cardNo" @keyup.enter="selectReadCard()" maxlength="20"/>
                        <i class="iconfont icon-sousuo" @click="selectReadCard()"></i>
                    </div> 
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>
            <div class="title">客户资料信息</div>
            <div class="tr-li">
                <div class="td-5">
                    <div class="lable-txt">手机号码：</div>
                    <div class="from-input">
                        <input type="text" keyBoard placeholder="请输入手机号" v-model="mobile" :readonly="!memberInfo.Bestech_Card_KindID || selectClientType==1"/>
                    </div>
                </div> 
                <div class="td-5">
                    <div class="lable-txt"><label><input keyBoard type="checkbox" v-model="isMobileCheck" :disabled="!memberInfo.Bestech_Card_KindID"/>验证手机号:</label></div>
                    <div class="from-input">
                        <div class="code"><input keyBoard type="text" placeholder="请输入验证码" :readonly="!memberInfo.Bestech_Card_KindID" v-model="verifyCode"/></div>
                        <div class="send-code loop-code" v-if="isCodeTime">{{codeTime}}秒</div>    
                        <div class="send-code" @click="readCode()" v-else>验证码</div> 
                    </div>
                </div>
            </div>
            <div class="tr-li">
                <div class="td-5">
                    <div class="lable-txt">客户姓名：</div>
                    <div class="from-input input-icon">
                        <input type="text" keyBoard placeholder="请输入或选择客户" :readonly="!memberInfo.Bestech_Card_KindID || selectClientType==1" v-model="cardName"/>
                        <i class="iconfont icon-kehu" @click="customerShow=true" :aria-disabled="!memberInfo.Bestech_Card_KindID"></i>
                    </div>
                </div>
                <div class="td-5">
                    <div class="lable-txt">会员等级：</div>
                    <div class="from-input">
                        <input type="text" v-model="memberLevel" readonly/>
                    </div>
                </div>
            </div>
            <div class="tr-li">
                <div class="td-5">
                    <div class="lable-txt">生日：</div>
                    <div class="from-input">
                       <el-date-picker class="from-date"
                            :clearable="false"
                            :editable="false"
                            type="date"
                            placeholder=""
                            v-model="birthday"
                            :readonly="!memberInfo.Bestech_Card_KindID || selectClientType==1"
                            :default-value="new Date()">
                        </el-date-picker>
                    </div>
                </div>
                <div class="td-5">
                    <div class="lable-txt">性别：</div>
                    <div class="from-input">
                        <label class="sex"><input type="radio" value="0" v-model="sex" name="sex" :disabled="!memberInfo.Bestech_Card_KindID || selectClientType==1"/><i class="iconfont icon-iconoption"></i>先生</label>
                        <label class="sex"><input type="radio" value="1" v-model="sex" name="sex" :disabled="!memberInfo.Bestech_Card_KindID || selectClientType==1"/><i class="iconfont icon-iconoption"></i>女士</label>
                    </div>
                </div>
            </div>
             <div class="tr-li">
                <div class="td-5">
                    <div class="lable-txt">身份证号：</div>
                    <div class="from-input">
                        <input type="text" keyBoard placeholder="请输入身份证" :readonly="!memberInfo.Bestech_Card_KindID || selectClientType==1" v-model="bodyCard"/>
                    </div>
                </div> 
                <div class="td-5">
                    <div class="lable-txt"></div>
                    <div class="from-input">
                    </div>
                </div>
            </div>
            <div class="tr-li">
                <div class="td-5">
                    <div class="lable-txt">交易密码：</div>
                    <div class="from-input"><input type="password" keyBoard placeholder="请输入" v-model="password" @blur="verifyPassword()" :readonly="!memberInfo.Bestech_Card_KindID" /></div>
                </div>
                <div class="td-5">
                    <div class="lable-txt">确认密码：</div>
                    <div class="from-input"><input type="password" keyBoard placeholder="请输入" v-model="newPassword" @blur="verifyPassword()" :readonly="!memberInfo.Bestech_Card_KindID" /></div>
                </div>
            </div>
            <div class="title">售卡信息</div>
            <div class="tr-li">
                <div class="td-5">
                    <div class="lable-txt">开卡人：</div>
                    <div class="from-input input-icon">
                        <input type="text" placeholder="请输入" readonly v-model="cardIssuer"/>
                        <i class="iconfont icon-lianxiren" @click="cardOpenerShow=true" :aria-disabled="!memberInfo.Bestech_Card_KindID"></i>
                    </div>
                </div>
                <div class="td-5">
                    <div class="lable-txt">工本费：</div>
                    <div class="from-input">
                        <input type="text" keyBoard placeholder="请输入" :readonly="!memberInfo.Bestech_Card_KindID" v-model="costMoney"/>
                    </div>
                </div>
            </div>
            <div class="tr-li">
                <div class="td-5">
                    <div class="lable-txt">充值金额：</div>
                    <div class="from-input input-icon">
                        <input type="text" placeholder="请输入" readonly v-model="rechargeMoney"/>
                        <i class="iconfont icon-chongzhijine" @click="recharge()" :aria-disabled="!memberInfo.Bestech_Card_KindID"></i>
                    </div>
                </div>
                <div class="td-5">
                    <div class="lable-txt">赠送金额：</div>
                    <div class="from-input">
                        <input type="text" placeholder="请输入" readonly v-model="giftMoney" />
                    </div>
                </div>
            </div>
            <div class="tr-li">
                <div class="td-5">
                    <div class="lable-txt">对卡金额：</div>
                    <div class="from-input">
                        <input type="text" placeholder="请输入" readonly  v-model="recharGiftMoney" />
                    </div>
                </div>
                <div class="td-5">
                    <div class="lable-txt">赠送积分：</div>
                    <div class="from-input">
                        <input type="text" placeholder="请输入" readonly v-model="pointNum"/>
                    </div>
                </div>
            </div>
            <div class="tr-li">
                <div class="lable-txt"></div>
                <div class="from-input">
                    <label><input type="checkbox" v-model="isInvoicing" :disabled="!memberInfo"/>是否开票</label>
                </div>
            </div>
            <div class="tr-li" :style="{visibility:isInvoicing?'':'hidden'}">
                <div class="td-5">
                    <div class="lable-txt">开票金额：</div>
                    <div class="from-input">
                        <input type="text" keyBoard placeholder="请输入" :readonly="!memberInfo.Bestech_Card_KindID" v-model="invoiceMoney" />
                    </div>
                </div>
                <div class="td-5">
                    <div class="lable-txt">发票号码：</div>
                    <div class="from-input">
                        <input type="text" keyBoard placeholder="请输入" :readonly="!memberInfo.Bestech_Card_KindID" v-model="invoiceNo"/>
                    </div>
                </div>
            </div>
            <div class="title">付款方式</div>
            <div class="pay-box">
                <div class="pay-scroll"  v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.item-li',marginH:8,isRow:false}">
                    <div class="item-li" :class="{selected:item.PAY_ID==SelectPayType.PAY_ID}" v-for="item in payType" :key="item" :aria-disabled="!memberInfo.Bestech_Card_KindID" @click="payClick(item)">
                        {{item.PAY_NAME}}<i class="iconfont icon-gou1"></i>
                    </div>
                    <div class="item-li" style="visibility: hidden;"></div>
                </div>
                <div class="item-operation">
                    <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                    <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="back()">返回</button>
            <button class="btn selected" @click="recordShow=true">查看办卡记录</button>
            <button class="btn selected" @click="confirm()" >确定办卡</button>
        </div>

        <!-- 选择开卡人 -->
        <modal-load :isShow="cardOpenerShow">
            <custodian-model :isShow="cardOpenerShow" @closeModel="cardOpenerShow=false" @custodianconfirm="custodianconfirm" ></custodian-model>
        </modal-load> 
        <!-- 选择客户 -->
        <modal-load :isShow="customerShow">
            <customer-model :isShow="customerShow" @closeModel="customerShow=false" @merconfirm="merconfirm" ></customer-model>
        </modal-load> 
        <!-- 充值记录 -->
        <modal-load :isShow="recordShow">
            <recharge-record-model :isShow="recordShow" @closeModel="recordShow=false" :crmOpenCard="7"></recharge-record-model>
        </modal-load>
        <!-- 充值列表 -->
        <modal-load :isShow="rechargeShow">
            <recharge-model :isShow="rechargeShow" @closeModel="rechargeShow=false" :MemberRechargePlan="memberInfo?.MemberRechargePlan" @rechargeClick="rechargeClick"></recharge-model>
        </modal-load> 
        <!--聚合支付-->
        <modal-load :isShow="scanCodeShow">
            <scanCodePay-model :isShow="scanCodeShow" :SelectPlanDetail="{Recharge_Money:rechargeMoney}" :Cardtype='0' :ispaySuccess="ispaySuccess" :CrmBizID="CrmBizID" @scanCodeconfirm="scanCodeconfirm" @scanCodecloseModel="scanCodecloseModel" ></scanCodePay-model>
        </modal-load> 
        <!--押金支付-->
        <modal-load :isShow="preMoneyShow">
            <member-pre-money-model :isShow="preMoneyShow" :rechargeMoney="rechargeMoney" 
                @close="preMoneyShow=false" @confirm="preMoneyConfirm" />
        </modal-load> 
    </div>
</template>

<script>
import { custodianModel,customerModel,rechargeModel,scanCodePayModel,rechargeRecordModel,memberPreMoneyModel } from '../model'
import { newGuid } from '/src/common/index.js'
import { isIdentityId }  from '../identity.js'

export default {
    components: { custodianModel,customerModel,rechargeModel,scanCodePayModel,rechargeRecordModel,memberPreMoneyModel },
    name:"membershipCard",
    data(){
        return {
            birthday:'',
            /**性别 0男 1女 */
            sex:0,
            /**选择开卡人 弹出层 */
            cardOpenerShow:false,
            /**选择客户 弹出层 */
            customerShow:false,
            /**充值列表 弹出层*/
            rechargeShow:false,
            /**聚合支付弹出层 */
            scanCodeShow:false,
            /**充值记录弹出层*/
            recordShow:false,
            /**卡号 */
            cardNo:'',
            /**内卡号 */
            cardSNR:'',
            /**卡id */
            card_AutoID:'',
            /**是否开票 */
            isInvoicing:false,
            /**工本费 */
            costMoney:'',
            /**会员姓名 */
            cardName:'',
            /**会员等级 */
            memberLevel:'',
            /**是否验证手机号 */
            isMobileCheck:false,
            /**手机号 */
            mobile:'',
            /**身份证号 */
            bodyCard:'',
            /**交易密码 */
            password:'',
            /**确认密码 */
            newPassword:'',
            /**会员数据 */
            memberInfo:Object,
            /**获取用户*/
            userInfo:'',
            /**支付方式 */
            payType:Object,
            /**充值金额 */
            recharGiftMoney:'',
            /**实收金额 */
            rechargeMoney:'',
            /**赠送金额 */
            giftMoney:'',
            /**赠送积分 */
            pointNum:'',
            /**获取的验证码 */
            verifyCode:'',
            /**倒计时 */
            codeTime:60,
            time:undefined,
            /**验证码是否倒计时 */
            isCodeTime:false,
            /**选中的支付方式 */
            SelectPayType:'',
            /**选择的充值方案 */
            SelectPlanDetail:'',
            /**开票金额 */
            invoiceMoney:'',
            /**发票号 */
            invoiceNo:'',
            /**开卡人 */
            cardIssuer:'',
            /**是否付款成功 */
            ispaySuccess:0,            
            selectClientType:0,
            /**会员业务流水ID, 如开卡支付的传 OpenCard_BizID 的值, 会员充值支付的传Recharge_BizID的值 */
            CrmBizID:'',
            readCardType:0,
            //押金支付 弹层
            preMoneyShow:false,
        }
    },
    watch:{
        cardNo(newVal){
            this.cardNo=(newVal+"").replace(/[^A-z0-9]/g,'').substr(0,20);
        },
        birthday(){
            if(new Date(this.birthday)>new Date()){
                this.$message.warning('不能选择未来日期')
                this.birthday=''
            }
        },
    },
    mounted(){
        this.$nextTick(()=> {
            this.userInfo=this.$auth.getUserInfo();
            this.$httpAES.post('Bestech.CloudPos.GetMemberPayments',{},'获取基础数据中...').then((data)=>{
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    this.payType=data.ResponseBody  
                }
            }).catch(()=>{
                this.$alert('获取支付方式失败', "提示", {confirmButtonText: "确定"});
            });
        })
    },
    methods:{

        /**退出 */
        back(){
            this.$emit("close");
        },
        /*刷新当前页面 */
        reload() {
            this.$emit("reload");
        },
        /**回撤 */
        keyEnter(e){
            if(e.keyCode==13){
                console.log(e);
            }
        },
        recharge(){
            if(this.readCardType==1){
                if(this.memberInfo?.MemberRechargePlan){
                     this.rechargeShow=true
                }else{
                    this.$message.error('没有适用该卡的充值方案,请先去CRM配置相对应的方案');
                }
               
            }
        },
        rechargeClick(PlanDetail,rechargeMoney,giftMoney,pointNum){
            this.SelectPlanDetail = PlanDetail;
            this.rechargeMoney = Number(rechargeMoney).toFixed(2)
            this.giftMoney = Number(giftMoney).toFixed(2)
            this.pointNum = parseFloat(pointNum)
            this.recharGiftMoney = parseFloat(Number(rechargeMoney).toFixed(2))+parseFloat(Number(giftMoney).toFixed(2))
            this.rechargeShow=false
        },
        payClick(item){
            this.SelectPayType = item;
            
            console.log(item)
        },
        /**验证密码一致性 */
        verifyPassword(){
            if(this.password!='' && this.newPassword!=''){
                if(this.password!=this.newPassword){
                    this.$message.warning('输入的密码不一致');
                }
            }
        },
        /**确认办卡*/
        confirm(data){
            if(Object.keys(this.memberInfo).length==0){
                this.$message.warning('请先查询开卡信息')
                return;
            }
            let regtel = /^1[0-9]{10}$/
            if(this.mobile!=''){
                if(!regtel.test(this.mobile)){
                    this.$message.warning('请输入正确的手机号');
                    return 
                }
            }
            let idmessage = isIdentityId(this.bodyCard)
            if(idmessage!='' && this.bodyCard!=''){
                this.$message.warning(idmessage);
                return 
            }
            if(this.isMobileCheck==true && this.verifyCode==''){
                this.$message.warning('短信验证码不能为空');
                return 
            }
            if(Number(this.costMoney)>0 || Number(this.rechargeMoney)>0){
                if(this.SelectPayType=='' || this.SelectPayType=='undefined'){
                    this.$message.warning('请选择付款方式');
                    return 
                }
            }
            
            if(this.password!=this.newPassword){
                this.$message.warning('输入的密码不一致');
                return 
            }
            if(this.birthday==''){
                this.birthday='1900-01-01'
            }
            if(this.cardIssuer==''){
                this.cardIssuer=this.userInfo?.Login_Name
            }
            if(this.SelectPayType.PAY_NAME=="聚合支付" && this.scanCodeShow==false){
                this.scanCodeShow=true
                return
            }else if(this.SelectPayType.INTERFACE_TYPE=='11' && !this.preMoneyShow){//预付金，押金

                if(Number(this.rechargeMoney)>0 && Number(this.costMoney)>0){
                    this.$message.warning("开卡存在工本费金额，开卡充值时不能用押金支付工本费！请先操作开卡，然后再去卡充值界面进行押金充值！")
                    return;
                }
                this.preMoneyShow=true;
                return;
            }
            let Recharge={
                Plan_ID:this.memberInfo?.MemberRechargePlan?.Plan_ID,
                Plan_DetailID:this.SelectPlanDetail.Plan_DetailID,//充值方案明细
                Is_Diy_ChargeMoney:this.SelectPlanDetail.Is_Diy_ChargeMoney,//是否自定义充值金额
                Recharge_Money: Number(this.rechargeMoney),//充值金额
                Recharge_GiftMoney: Number(this.giftMoney),//充值赠送金额
                Recharge_GiftPoint: Number(this.pointNum), //充值赠送积分
                Is_Invoice:this.isInvoicing, //是否开票
                Cost_Money: 0, //成本金额
                Invoice_Money:Number(this.invoiceMoney), //开票金额
                Invoice_No:this.invoiceNo,//开票号
            }
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo.User_ID,  //用户id
                Card_SN:this.cardSNR,
                Card_No:this.cardNo,//卡号
                Mobile:this.mobile, //手机号
                Is_CheckSMS:this.isMobileCheck, //是否验证手机号
                SMS_CheckCode:this.verifyCode, //短信验证码
                Member_Name:this.cardName,//会员姓名
                BodyCard_ID:this.bodyCard, //身份证号
                Gender:this.sex, //0男 1女
                Birthday:this.birthday, //生日
                Rpt_Date:this.userInfo?.Rpt_Date, //营业日期
                OpenCard_Czy:this.cardIssuer,//开卡操作员
                OpenCard_PosName:this.userInfo?.Site_Name,//开卡站点
                OpenCard_CostMoney: Number(this.costMoney),//开卡工本费
                OpenCard_PayType:this.SelectPayType.INTERFACE_TYPE,//付款方式类型
                OpenCard_PayCode:this.SelectPayType.PAY_CODE, //付款方式编号
                Recharge_PayID:this.SelectPayType.PAY_ID,//充值支付方式ID
                Card_PWD:this.password,//卡密码
                Invoice_Money:Number(this.invoiceMoney),//开票金额
                Invoice_No:this.invoiceNo,//发票号
                OpenCard_BizID:''//开卡流水号
            }
            Object.assign(param,data);
            if(Number(this.rechargeMoney)>0){
                param.OpenCardRecharge=Recharge;//开卡充值信息
            }
            const loading = this.$loading({
                text: "开卡验证中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.OpenActualCardDirect",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.CrmBizID = d.ResponseBody.OpenCard_BizID;
                    this.ispaySuccess=0
                    if(this.scanCodeShow==true){
                        this.scanCodeShow=false;
                    }else if(this.preMoneyShow){
                        this.preMoneyShow=false;
                    }
                    this.OpenActualCardDirectSubmit(d.ResponseBody)
                }else{
                    if(d.ResponseHeader.ResultCode>0 && this.scanCodeShow==true){
                        this.CrmBizID = d.ResponseBody?.OpenCard_BizID
                        if(d.ResponseHeader.ResultCode==4003){
                            this.ispaySuccess=0
                            this.$message.error(d.ResponseHeader.ResultDesc);
                        }else if(d.ResponseHeader.ResultCode==4001){
                            this.ispaySuccess=1
                            this.$message.error(d.ResponseHeader.ResultDesc);
                        }else{
                            this.ispaySuccess=1
                            this.$message.error(d.ResponseHeader.ResultDesc);
                        }
                    }else{
                        this.ispaySuccess=0
                        this.reload()
                        this.$message.error(d.ResponseHeader.ResultDesc);
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
        },
        /**开卡提交 */
        OpenActualCardDirectSubmit(Direct){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo.User_ID,  //用户id
                OpenCard_BizID:Direct.OpenCard_BizID,//开卡请求流水号
                Payment_Code:this.SelectPayType.PAY_CODE, //支付方式编号
                WX_Pay_SerialNo:Direct.WX_Pay_MchID, //聚合支付成功流水号
                //Is_OpenCardOnly:false, //执行开卡操作
                Operate_User:this.userInfo?.Login_Name, //操作员
                Operate_PosName:this.userInfo?.Site_Name //操作站点
            }
            const loading = this.$loading({
                text: "开卡提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.OpenActualCardDirectSubmit",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(d.ResponseBody.OpenCardOK==true){
                        if(d.ResponseBody?.PrintsInfo){
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(d.ResponseBody?.PrintsInfo);
                            },100);
                        }
                        this.$message.success("开卡完成");
                        this.reload()
                    }else{
                        this.$message.error(d.ResponseBody.Msg);
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error(e);
                console.log(e);
            })
            
        },
        /**点击查询图标读卡 */
        selectReadCard(){
            if(!this.cardNo && !this.cardSNR){
                this.$message.warning('请填写会员卡号')
                return
            }
            const loading = this.$loading({
                text: "读取会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                DynamicNo:this.cardNo,
                Card_AutoID:this.card_AutoID,
                Card_SN:this.cardSNR,
                IsGetRechargePlan:true, //获取会员的充值方案
                IsGetNotIssuedCard:true //是否获取未发卡的记录（未激活的卡)
            }
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode==0){
                    if(data.ResponseBody[0].Card_Status=='已发卡'){
                         this.$message.warning('该卡号已发卡');
                         return
                    }
                    this.cardSNR=''
                    this.card_AutoID=''
                    this.memberLevel = data.ResponseBody[0]?.Bestech_Card_KindName
                    this.sex = data.ResponseBody[0]?.Gender
                    this.mobile = data.ResponseBody[0]?.Mobile
                    this.memberInfo = data.ResponseBody[0]
                    this.readCardType=1
                    this.selectClientType=0
                }else{
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.cardSNR=d.data.snr
                        this.selectClientType=0
                        this.selectReadCard()
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**获取内卡号 */
        redCardSNR(successFun){
            this.$webBrowser.redCardSNR().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.cardSNR=d.data.snr
                        if(this.isSNRInCardNo){
                            this.cardNo=d.data;
                        }
                        if(typeof(successFun)=="function") successFun();
                    }else{
                        this.isInProgress=false;
                        this.isStopMakeCard=true;
                        this.output+=this.cardNo+"制卡失败："+d.message+"\r\n";
                    }
                }
            })
        },
        /**倒计时 */
        doLoop(){
            this.codeTime=60;
            this.isCodeTime=true;
            if(this.time) clearInterval(this.time);
            this.time=setInterval(() => {
                if(this.codeTime>0){
                    this.codeTime--;
                }else{
                    clearInterval(this.time);
                    this.isCodeTime=false;
                }
            }, 1000);
        },
        /**发送验证码 */
        readCode(){
            if(this.isMobileCheck==true){
                const  reg = /^1[0-9]{10}$/
                if(this.mobile.length<10 || !reg.test(this.mobile)){
                    this.$message.error('请输入正确的手机号');
                    return
                }
                const loading = this.$loading({
                    text: "发送验证码中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                this.$httpAES.post("Bestech.CloudPos.SendMobileSMSCode",{
                    App_Type:2,
                    Mobile:this.mobile,
                    Verify_Type:0,
                    Verify_Code:''
                }).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode==0){
                        this.doLoop();
                        this.$message.success("验证码发送成功,请注意查收");
                    }else{
                        this.$message.error("验证码发送失败："+d.ResponseHeader.ResultDesc);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$message.error('验证码发送失败：'+e);
                    console.log('验证码发送失败：'+e);
                })
            }
        },
        /**聚合支付关闭 */
        scanCodecloseModel(type){
            //type:0 关闭聚合支付弹窗 1不可关闭 2关闭聚合支付弹窗并去执行OpenActualCardDirectSubmit方法
            if(type==0){
                this.ispaySuccess=0
                this.scanCodeShow=false
            }else if(type==5){
                this.ispaySuccess=0
                this.scanCodeShow=false
                this.reload()
            }else{
                this.ispaySuccess=1
            }
        },
        /**聚合支付返回 */
        scanCodeconfirm(payCode){
            this.confirm({
                SsPay_QrCode:payCode,//聚合支付二维码信息
            })
        },
        /**押金支付返回 */
        preMoneyConfirm(param){
            this.confirm({
                DepositRechargePayments:param//押金单支付列表，选押金支付时必传
            });
        },
        /**选择客户返回*/
        merconfirm(SelectClient){
            if(SelectClient!=''){
                this.selectClientType=1
            }
            this.cardName = SelectClient.ClientName
            this.birthday = SelectClient.Birthday
            this.sex = SelectClient.Gender
            this.mobile = SelectClient.Mobile
            this.bodyCard = SelectClient.BodyCard_ID
            this.customerShow=false
        },
        /**开卡人返回 */
        custodianconfirm(seleUser){
            this.cardIssuer = seleUser.User_Name
            this.cardOpenerShow = false
        }
    },
    beforeUnmount(){
       if(this.time) clearInterval(this.time);
    }
}
</script>

<style lang='scss'>
@import "./membershipCard.scss";
</style>